
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: #0B0B0B;
    font-family: -apple-system, BlinkMacSystemFont, 
                 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 
                 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  img {
    user-select: auto; 
  }
  

 
