@import url('https://fonts.googleapis.com/css?family=Monoton');
@import url('https://fonts.googleapis.com/css?family=Roboto');
.title {
    color: #FFF;
    text-align: center;
    font-family: Monoton;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 3px;
    padding-top: 5rem;
}

.slogan {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 100;
    letter-spacing: 3px;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  /* .footer-links {
    position: absolute;
    bottom: 0;
    left: 1rem;
    padding: 30px;
}

.footer-links a {
    padding-right: 3rem; 
    color: #FFF; 
    text-decoration: none; 
    font-family: Roboto;
    font-weight: 10;
    font-size: 20px;
    
} */
/* .footer-links {
    padding: 30px;
   
}
.footer-links a {
    padding-right: 3rem;   
    color: #FFF;
    text-decoration: none;
    font-family: Roboto;
    font-weight: 10;
    font-size: 20px;
} 

@media (max-width: 768px) {
    .footer-links a {
        padding-right: 0rem;  
        font-size: 12px;
        text-align: center;
        display: flex; 
        justify-content: center; 
    }
} */

.footer-links {
    display: flex; 
    padding: 30px;
}

.footer-links a {
    color: #FFF;
    text-decoration: none;
    font-family: Roboto;
    font-weight: 10;
    font-size: 20px;
    padding: 0 1.5rem; /* Adjust the padding as necessary */
}

@media (max-width: 768px) {
    .footer-links {
        flex-direction: row; /* Stack children vertically on small screens */
        align-items: center; /* Align children in the center */
        justify-content: center; /* Center items horizontally */
        padding: 30px;
    }
    
    .footer-links a {
        font-size: 12px;
    }

    .title {
        padding-top: 2rem;
    }
}

.landing-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    min-height: 100vh; 

}

.content {
    flex: 1; 
}




