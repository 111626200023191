@import url('https://fonts.googleapis.com/css?family=Roboto');

.download-app-popup {
    color: black;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff; 
    padding: 50px;
    padding-top: 2px;
    padding-bottom: 5px;
    text-align: center;
    font-family: Roboto;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); 
}

.open-in-app-button {
    padding-bottom: 100px !important;
    margin-bottom: 20px;
}




